import { required } from 'vuelidate/lib/validators'
import { guidValidator } from '@/components/validators'

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    detailGroup: ['entity.miscQuoteId', 'entity.customerId'],
    entity: {
      miscQuoteId: {
        required,
        validGuid: guidValidator
      },
      customerId: {
        required,
        validGuid: guidValidator
      },
      items: {
        $each: {
          itemNo: {
            required
          },
          itemDesc: {
            required
          }
        }
      },
      invoices: {
        $each: {
          assetId: {
            required,
            validGuid: guidValidator
          }
        }
      }
    }
  }
}
