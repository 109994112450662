<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <misc-quote-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entities"
                :key="entity.id"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>
                  <router-link :to="{ path: entity.miscQuoteId }"
                    append>
                    {{ entity.miscQuoteNoRef }}
                  </router-link>
                </td>
                <td>{{ $filters.formatDateTimezone(entity.miscQuoteDate, $userInfo.locale) }}</td>
                <td>{{ entity.customerName }}</td>
                <td class="has-text-right">{{ entity.totalExGst | formatCurrency($userInfo.locale) }}</td>
                <td class="has-text-right">{{ entity.gst | formatCurrency($userInfo.locale) }}</td>
                <td class="has-text-right">{{ entity.invoiceNo }}</td>
                <td class="has-text-centered">
                  <span class="icon"
                    :class="[ !entity.deleted ? 'has-text-success' : 'has-text-danger' ]">
                    <i class="mdi mdi-24px"
                      :class="[ !entity.deleted ? 'mdi-check' : 'mdi-close' ]" />
                  </span>
                </td>
                <td class="has-text-centered">
                  <router-link :to="{ path: entity.miscQuoteId }"
                    append>
                    <a class="button is-primary is-small is-inverted tooltip"
                      data-tooltip="Click to edit">
                      <span class="icon is-medium">
                        <i class="mdi mdi-lead-pencil mdi-24px" />
                      </span>
                    </a>
                  </router-link>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import { Columns } from './columns'
import StoreMixin from './storeMixin'
import BulmaTable from '@/components/BulmaTable'
import MiscQuoteListSideMenu from './MiscQuoteListSideMenu'
import MiscQuoteService from './MiscQuoteService'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { AppHeaderButtonTypes } from '@/enums'
import { KeyValuePairModel } from '@/classes/viewmodels'
import { Emailer } from '@/classes'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import MiscQuoteRoutes from './route-types'

export default {
  name: 'MiscQuoteListView',
  components: {
    AppDetailHeader,
    BulmaTable,
    MiscQuoteListSideMenu
  },
  mixins: [StoreMixin, DateTimeFiltersMixin, NumberFiltersMixin],
  props: {},
  data() {
    return {
      entities: null,
      totalRows: 0,
      filter: {
        miscQuoteNo: '',
        customerName: '',
        invoiceNo: '',
        active: 1,
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      selectedRow: null,
      isTableLoading: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  created() {
    this.getEntitySummaries()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addNew()
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    async addNew() {
      this.addStoreItem(0, false)
    },
    highlightSelected(index, event) {},
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getEntitySummaries()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getEntitySummaries()
    },
    filterList() {
      this.persistFilter()
      this.getEntitySummaries()
    },
    resetList() {
      this.filter.miscQuoteNo = ''
      this.filter.customerName = ''
      this.filter.invoiceNo = ''
      this.filter.active = 1
      this.filter.sortColumn = Columns[0].name
      this.filter.sortOrder = Columns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.$eventHub.$emit('filterReset')
      this.persistFilter()
      this.getEntitySummaries()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter.miscQuoteNo = filter.miscQuoteNo
        this.filter.customerName = filter.customerName
        this.filter.invoiceNo = filter.invoiceNo
        this.filter.active = filter.active
        this.filter.sortColumn = filter.sortColumn
        this.filter.sortOrder = filter.sortOrder
        this.filter.pageIndex = filter.pageIndex
        this.filter.pageSize = filter.pageSize
      }
    },
    async getEntitySummaries() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entities = await MiscQuoteService.getEntitySummaries(this.filter)
      this.totalRows = this.entities.length > 0 ? this.entities[0].totalRows : 0
      this.isTableLoading = false
    },
    print() {
      const params = Object.assign(
        {
          CompanyID: this.$userInfo.companyId,
          MiscQuoteNo: this.filter.miscQuoteNo,
          CustomerName: this.filter.customerName,
          InvoiceNo: this.filter.invoiceNo,
          SortColumn: this.filter.sortColumn,
          PageIndex: this.filter.pageIndex,
          Active: this.filter.active,
          PageSize: this.totalRows,
          SortDirection: this.filter.sortOrder
        }
      )
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.subject = 'Misc Quote Listing'
      emailer.reportName = 'MiscQuoteList'
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'rptMiscQuoteListV2' },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === MiscQuoteRoutes.MiscQuoteDetail.name) {
      this.setReadOnlyView(false)
    }
    next()
  }
}
</script>
