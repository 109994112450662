<template>
  <div class="tile is-parent is-half">
    <article class="tile is-child box detail-page-tile">
      <div class="columns is-gapless">
        <div class="column">
          <p class="title">Items</p>
          <p class="subtitle">Manage items</p>
        </div>
        <div class="column">
          <div v-if="!readOnlyView"
            class="field is-pulled-right">
            <p class="buttons">
              <button
                class="button is-primary tooltip is-tooltip-topright"
                data-tooltip="Add new item"
                @click="toggleQuickItemList()">
                <span class="icon">
                  <i
                    class="mdi"
                    :class="{ 'mdi-plus' : !isQuickItemListActive, 'mdi-minus' : isQuickItemListActive }" />
                </span>
                <span>{{ isQuickItemListActive ? 'Close' : 'Add' }}</span>
              </button>
              <button
                class="button is-primary tooltip is-tooltip-topright"
                data-tooltip="Add blank item"
                @click="addBlankItem">
                <span class="icon">
                  <i class="mdi mdi-shape-square-plus" />
                </span>
              </button>
            </p>
          </div>
        </div>
      </div>
      <misc-quote-available-items
        v-show="isQuickItemListActive"
        v-model="innerValue.items"
        :misc-quote-id="innerValue.miscQuoteId"
        default-gl-account="41110" />
      <div v-show="isQuickItemListActive"
        class="is-divider" />
      <table class="table is-bordered is-striped is-narrow is-fullwidth">
        <thead>
          <tr>
            <th>Description</th>
            <th class="has-text-right">Qty</th>
            <th class="has-text-right">Unit Price</th>
            <th class="has-text-right">Total Ex Gst</th>
            <th>G/L Account</th>
            <th v-if="!readOnlyView" />
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th class="has-text-right"
              colspan="3">Totals</th>
            <th class="has-text-right">{{ totalExtGst | formatCurrency($userInfo.locale) }}</th>
            <!-- <th class="has-text-right">{{ innerValue.gst | formatCurrency($userInfo.locale) }}</th> -->
            <th colspan="2" />
          </tr>
        </tfoot>
        <tbody v-if="readOnlyView">
          <tr v-for="(item, index) in activeItems"
            :key="item.miscQuoteItemId">
            <td>{{ item.itemDesc }}</td>
            <td class="has-text-right">{{ item.quantity }}</td>
            <td class="has-text-right">{{ item.unitPrice | formatNumber($userInfo.locale) }}</td>
            <td class="has-text-right">{{ item.total | formatNumber($userInfo.locale) }}</td>
            <td class="has-text-right">{{ item.gst | formatNumber($userInfo.locale) }}</td>
            <td
              v-if="selectedItemAccounts.length > 0"
              class="is-nowrap">{{ selectedItemAccounts[index].displayName }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr
            v-for="(item, index) in activeItems"
            :key="item.miscQuoteItemId">
            <td>
              <textarea
                class="textarea"
                :class="{ 'is-danger' : !$v.entity.items.$each[index].itemDesc.required }"
                v-focus-inserted
                placeholder="Item Description"
                rows="2"
                v-model="item.itemDesc" />
            </td>
            <td class="has-text-right quantity-input">
              <vue-numeric
                class="input has-text-right"
                :min="0"
                :max="99"
                :precision="0"
                v-model.number="item.quantity"
                @input="updateItem(item)" />
            </td>
            <td class="has-text-right money-input">
              <vue-numeric class="input has-text-right"
                v-model.number="item.unitPrice"
                :min="0"
                :precision="2"
                @input="updateItem(item)" />
            </td>
            <td class="has-text-right">{{ item.quantity * item.unitPrice | formatNumber($userInfo.locale) }}</td>
            <td class="account-input">
              <multiselect v-if="glAccountListCombo && selectedItemAccounts.length > 0"
                v-model="selectedItemAccounts[index]"
                :options="glAccountListCombo"
                placeholder="Select account"
                label="displayName"
                track-by="accountNo"
                deselect-label="Can't remove this value"
                :show-labels="false"
                :allow-empty="false">
                <span
                  class="has-text-danger"
                  slot="noResult">G/L account not found.</span>
              </multiselect>
            </td>
            <td class="has-vertical-middle has-text-centered is-content-width">
              <a
                class="button is-danger is-small is-inverted"
                @click="deleteItem(item, index)">
                <span class="icon is-medium">
                  <i class="mdi mdi-delete mdi-24px" />
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </article>
  </div>
</template>

<script>
import { NumberFiltersMixin } from '@/components/mixins/filters'
import _cloneDeep from 'lodash/cloneDeep'
import VueNumeric from '@/components/VueNumeric'
import Multiselect from 'vue-multiselect'
import { FocusInserted } from '@/components/directives'
import MiscQuoteService from './MiscQuoteService'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import MiscQuoteAvailableItems from './MiscQuoteAvailableItems'
import { MiscQuoteItemModel } from '@/classes/viewmodels'
import { EventHubTypes } from '@/enums'

export default {
  name: 'MiscQuoteItems',
  inject: ['$vv'],
  components: {
    VueNumeric,
    Multiselect,
    MiscQuoteAvailableItems
  },
  directives: {
    FocusInserted
  },
  mixins: [NumberFiltersMixin],
  props: {
    value: null
  },
  data: () => {
    return {
      innerValue: null,
      readOnlyView: false,
      glAccountListCombo: [],
      selectedItemAccounts: [],
      isQuickItemListActive: false
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    activeItems() {
      return this.innerValue.items.filter(i => !i.isDeleted)
    },
    totalExtGst() {
      return roundAwayFromZero(this.activeItems.reduce(function(total, item) {
          return total + (item.quantity * item.unitPrice)
        }, 0), 2)
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    'innerValue.items': {
      // item addition/deletion watcher
      handler: function(newVal, oldVal) {
        this.generateSelectedAccounts()
      },
      deep: true
    },
    selectedItemAccounts: {
      handler: function(newVal, oldVal) {
        if (newVal && newVal.length > 0) {
          const vm = this
          newVal.forEach(function(account, index) {
            if (account) {
              vm.innerValue.items[index].itemGlCode = account.accountNo
              vm.innerValue.items[index].glCategory = account.glCategory
            }
          })
        }
      }
    }
  },
  beforeDestroy() {},
  async created() {
    this.innerValue = _cloneDeep(this.value)
    this.glAccountListCombo = await MiscQuoteService.getGlAccountDropdown()
    this.generateSelectedAccounts()
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  mounted() {
    this.$v.entity.items.$touch()
  },
  methods: {
    generateSelectedAccounts() {
      const vm = this
      vm.selectedItemAccounts = []
      this.innerValue.items.filter(i => !i.isDeleted).forEach(function(item) {
        vm.selectedItemAccounts.push(vm.glAccountListCombo.find(g => g.accountNo === item.itemGlCode))
      })
    },
    updateItem(item) {
      this.updateQuoteTotal()
      // const totalExtGst = this.activeItems.reduce(function(total, item) {
      //     return total + (item.quantity * item.unitPrice)
      //   }, 0)
      // this.innerValue.gst = roundAwayFromZero((this.innerValue.gstRate / 100) * totalExtGst)
      // this.innerValue.total = roundAwayFromZero(totalExtGst + this.innerValue.gst)
    },
    updateGstTotal(item) {},
    updateQuoteTotal() {
      const totalExtGst = this.activeItems.reduce(function(total, item) {
          return total + (item.quantity * item.unitPrice)
        }, 0)
      this.innerValue.gst = roundAwayFromZero((this.innerValue.gstRate / 100) * totalExtGst)
      this.innerValue.total = roundAwayFromZero(totalExtGst + this.innerValue.gst)
    },
    toggleQuickItemList() {
      this.isQuickItemListActive = !this.isQuickItemListActive
    },
    addBlankItem() {
      const newItem = new MiscQuoteItemModel(
          this.innerValue.miscQuoteId,
          `Q${this.innerValue.items.length + 500}`,
          '',
          1,
          0,
          '41110',
          this.innerValue.items.length + 1
        )
      this.innerValue.items.push(newItem)
    },
    deleteItem(item, index) {
      if (item.isNew) {
        const itemIndex = this.innerValue.items
          .map(function(obj) {
            return obj.miscQuoteItemId
          })
          .indexOf(item.miscQuoteItemId)
        if (itemIndex >= 0) {
          this.innerValue.items.splice(itemIndex, 1)
        }
      } else {
        item.isDeleted = true
      }
      this.updateQuoteTotal()
    }
  }
}
</script>