<template>
  <div>
    <app-side-menu
      :min-width="18"
      :fixed-menu="false">
      <aside class="menu"
        slot="side-menu">
        <div class="menu-label">
          <div class="columns is-gapless">
            <div class="column">
              <span class="title is-6"
                style="white-space: nowrap">
                Quote No: {{ entityName }}
              </span>
            </div>
          </div>
        </div>
        <ul class="menu-list">
          <li>
            <router-link :to="{ name: routeTypes.MiscQuoteDetail.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              exact>
              <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded': $v.detailGroup.$error }"
                :data-badge="$v.detailGroup.$error ? '' : null">
                <span class="has-badge-child-offset2">Details</span>
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: routeTypes.MiscQuoteInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              event=""
              :class="{ 'is-disabled': enableItemRoutes }"
              @click.native.prevent="goToRoute({ name: routeTypes.MiscQuoteInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
              exact>
              <span class="has-badge-child-offset3">Invoice</span>
              <span v-if="invoice"
                class="has-badge-rounded has-badge-medium"
                :class="[$v.entity.invoices.$error ? 'has-badge-danger has-badge-icon-exclamation' : (invoice.isNew ? 'has-badge-icon-check has-badge-gold' : 'has-badge-icon-check has-badge-success')]"
                data-badge="" />
            </router-link>
          </li>
        </ul>
        <p class="menu-label">
          Items
        </p>
        <ul class="menu-list">
          <li>
            <router-link :to="{ name: routeTypes.MiscQuoteItems.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
              active-class="is-active"
              exact>
              <span>
                <span>
                  <span class="has-badge-child-offset3">Items</span>
                  <span v-show="value.items"
                    class="has-badge-rounded has-badge-medium"
                    :class="[$v.entity.items.$error ? 'has-badge-danger' : 'has-badge-info']"
                    :data-badge="value.items.filter(i => !i.isDeleted).length" />
                </span>
              </span>
            </router-link>
          </li>
        </ul>
      </aside>
    </app-side-menu>
  </div>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import MiscQuoteRoutes from './route-types'

export default {
  name: 'MiscQuoteSideMenu',
  inject: ['$vv'],
  components: {
    AppSideMenu
  },
  props: {
    value: null,
    entityName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    routeTypes() {
      return MiscQuoteRoutes
    },
    invoice() {
      return this.value.invoices.find(i => !i.deleted)
    },
    enableItemRoutes() {
      return this.value.isNew
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    goToRoute(route) {
      if (!this.enableItemRoutes && route.name === MiscQuoteRoutes.MiscQuoteInvoice.name)
      {
        this.$router.push(route)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-disabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  // pointer-events: none;
  a {
    display: inline-block;
    // pointer-events: none;
    text-decoration: none;
  }
}

span.superscript {
  vertical-align: super;
  font-size: smaller;
}

span.has-badge-child {
  margin-right: 1em;
}

.has-badge-icon {
  &::after {
    font-family: 'Material Design Icons';
    content: '';
    //content: attr(data-badge);
    // content: attr(data-test);
  }
}

.has-badge-icon-check {
  &::after {
    font-family: 'Material Design Icons';
    content: '\f012c';
    //content: attr(data-test);
  }
}

.has-badge-icon-exclamation {
  &::after {
    font-family: 'Material Design Icons';
    content: '\f0205';
  }
}
</style>
