<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-vertical is-half">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <div class="columns">
            <div class="column">
              <p class="title">Invoice</p>
            </div>
            <div class="column is-narrow">
              <div v-if="!isLoadingInvoice"
                class="buttons">
                <a v-show="$user.hasEdit($route.meta.id) && !readOnlyView && !isUpdateInvoice && invoice && !invoice.gstPaidDate"
                  class="button tooltip"
                  data-tooltip="Update Invoice"
                  @click="editInvoice()">
                  <span class="icon has-text-primary">
                    <i class="mdi mdi-pencil mdi-24px" />
                  </span>
                </a>
                <a v-show="$user.hasAdd($route.meta.id) && !invoice && !readOnlyView"
                  class="button tooltip"
                  data-tooltip="New Invoice"
                  @click="addInvoice()">
                  <span class="icon has-text-success">
                    <i class="mdi mdi-plus mdi-24px" />
                  </span>
                </a>
                <a v-show="$user.hasDelete($route.meta.id) && !readOnlyView && !isUpdateInvoice && invoice && !invoice.gstPaidDate || (invoice && invoice.isNew)"
                  class="button tooltip"
                  data-tooltip="Delete Invoice"
                  @click="deleteInvoice()">
                  <span class="icon has-text-danger">
                    <i class="mdi mdi-delete mdi-24px" />
                  </span>
                </a>
                <a v-show="$user.hasEdit($route.meta.id) && isUpdateInvoice && invoice && !invoice.isNew"
                  class="button tooltip"
                  data-tooltip="Update from quote"
                  @click="updateInvoice()">
                  <span class="icon has-text-primary">
                    <i class="mdi mdi-refresh mdi-24px" />
                  </span>
                </a>
                <a v-show="isUpdateInvoice && invoice && !invoice.isNew"
                  class="button tooltip"
                  data-tooltip="Cancel"
                  @click="cancelInvoice()">
                  <span class="icon has-text-warning">
                    <i class="mdi mdi-close mdi-24px" />
                  </span>
                </a>
              </div>
              <span v-if="invoice && invoice.gstPaidDate"
                class="tag is-warning is-pulled-right tooltip is-tooltip-warning"
                data-tooltip="GST has been paid">GST paid on {{ $filters.formatDateTimezone(invoice.gstPaidDate, $userInfo.locale) }}</span>
            </div>
          </div>
          <div v-if="!invoice && isLoadingInvoice"
            class="section">
            <span class="is-loading-image has-text-grey has-text-centered" />
          </div>
          <div v-else-if="invoice"
            style="height: 93%; align-self: stretch">
            <div class="field-body">
              <div class="field">
                <label class="label">Invoice No.</label>
                <div class="field has-addons">
                  <div class="control">
                    <input class="input is-static"
                      :value="invoiceNoReference"
                      type="text"
                      placeholder="Invoice No."
                      readonly>
                  </div>
                  <!-- <div v-if="isUpdateInvoice && ($userInfo.isSupportUser || $userInfo.isCustomerAdministrator)"
                    class="control">
                    <button class="button is-warning is-inverted tooltip is-tooltip-warning"
                      data-tooltip="Change invoice no."
                      @click="showChangeInvoiceNoModal">
                      <span class="icon is-medium">
                        <i class="mdi mdi-pencil-outline mdi-24px" />
                      </span>
                    </button>
                  </div> -->
                </div>
              </div>
              <div v-if="!isUpdateInvoice"
                class="field">
                <label class="label">Invoice Date</label>
                <input class="input is-static"
                  :value="$filters.formatDateTimezone(selectedInvoiceDate, $userInfo.locale)"
                  readonly>
              </div>
              <div v-else-if="$userInfo && !gstPaid && selectedInvoiceDate"
                class="field">
                <label class="label">Invoice Date</label>
                <v-date-picker v-model="selectedInvoiceDate"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  :min-date="firstDayOfMonth"
                  @input="selectedInvoiceDate = $filters.fromISOWithCurrentTime($event.toISOString())"
                  :timezone="$filters.getIanaTimezone()">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field">
                      <div class="control has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Date To..."
                          :value="inputValue"
                          v-on="inputEvents"
                          readonly>
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="is-divider" />
            <div class="field">
              <label class="label">Payer</label>
              <input class="input is-static"
                :value="invoice.assetName"
                type="text"
                readonly>
            </div>
            <div class="is-divider" />
            <label class="label">Exported</label>
            <div class="pretty p-icon p-curve p-smooth p-bigger">
              <input id="chk-invoice-exported"
                name="chk-invoice-exported"
                v-model="invoice.exported"
                type="checkbox"
                :disabled="readOnlyView || !$userInfo.isSupportUser">
              <div class="state p-primary">
                <i class="icon mdi mdi-check" />
                <label>Exported</label>
              </div>
            </div>
            <div class="is-divider" />
            <div class="field">
              <label class="label">Total Excl GST: <span class="has-text-success">{{ totalExGst | formatCurrency($userInfo.locale) }}</span></label>
            </div>
            <div class="field">
              <label class="label">GST: <span class="has-text-success">{{ gst | formatCurrency($userInfo.locale) }}</span></label>
            </div>
            <div class="field">
              <label class="label">Total Payable Incl GST: <span class="has-text-success">{{ totalPayableIncGst | formatCurrency($userInfo.locale) }}</span></label>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import StoreMixin from './storeMixin'
import MiscQuoteInvoiceService from './services/MiscQuoteInvoiceService'
import { InvoiceTypes, AssetTypes, InvoicePayerTypes, EventHubTypes } from '@/enums'
import { InvoiceItemModel } from '@/classes/viewmodels'

export default {
  name: 'MiscQuoteInvoice',
  components: {},
  mixins: [StoreMixin, DateTimeFiltersMixin, NumberFiltersMixin],
  props: {
    value: null
  },
  data: () => {
    return {
      innerValue: null,
      isLoadingInvoice: false,
      invoice: null,
      selectedInvoiceDate: null,
      gstPaid: false,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    invoiceNoReference() {
      return this.invoice ? `${this.invoice.prefix}${this.invoice.invoiceNo}${this.invoice.suffix}/${this.invoice.invoiceType}` : ''
    },
    totalExGst() {
      return this.invoice.totalExGst
    },
    gst() {
      return this.invoice.gst
    },
    totalIncGst() {
      return this.invoice.totalIncGst
    },
    totalPayableIncGst() {
      return this.invoice.totalIncGst
    },
    firstDayOfMonth() {
      if (this.$company.setting.allowInvoicePreviousMonth) {
        return null
      }
      let invoiceDate = new Date()
      if (!this.invoice.isNew) {
        invoiceDate = new Date(`${this.invoice.invoiceDate}`)
        return this.$filters.firstDayOfMonth(invoiceDate.getFullYear(), invoiceDate.getMonth())
      } else {
        return this.$filters.firstDayOfMonth()
      }
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    selectedInvoiceDate: function(newVal, oldVal) {
      if (newVal && this.isUpdateInvoice) {
        this.invoice.invoiceDate = newVal.toISOString().split('.')[0] + 'Z'
      } else if (this.isUpdateInvoice) {
        this.invoice.invoiceDate = null
      }
    }
  },
  created() {
    this.innerValue = _cloneDeep(this.value)
    this.getInvoice()
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
      this.getInvoice()
    })
  },
  mounted() {},
  methods: {
    getInvoice() {
      this.invoice = this.innerValue.invoices.find(i => !i.deleted)
      if (this.invoice) {
        this.selectedInvoiceDate = new Date(`${this.invoice.invoiceDate}`)
      }
    },
    updateInvoice() {
      this.invoice.items= []
      const items = this.innerValue.items.filter(i => !i.isDeleted)
      const vm = this
      items.forEach(i => {
        const newItem = new InvoiceItemModel(
          vm.$userInfo.companyId,
          vm.invoice.invoiceId,
          i.itemGlCode,
          i.itemDesc,
          i.quantity,
          i.unitPrice,
          vm.innerValue.gstRate
        )
        newItem.quoteItemId = i.miscQuoteItemId
        newItem.glCategory = i.glCategory
        this.invoice.items.push(newItem)
      })
      this.invoice.assetId = this.innerValue.customerId
      this.invoice.assetType = AssetTypes.Customer
      this.invoice.assetName = this.storeCustomer.fullname
      this.invoice.contactId = this.innerValue.contactId
      this.invoice.totalIncGst = this.innerValue.total
      this.invoice.gst = this.innerValue.gst
      this.invoice.totalExGst = this.innerValue.total - this.innerValue.gst
    },
    editInvoice() {
      this.setIsUpdateInvoice(true)
      this.updateInvoice()
    },
    cancelInvoice() {
      this.setIsUpdateInvoice(false)
      const baseInvoice = this.snapshots[0].invoices.find(i => i.invoiceId === this.invoice.invoiceId)
      // console.log(baseInvoice)
      this.invoice.items = baseInvoice.items
      this.invoice.totalIncGst = baseInvoice.totalIncGst
      this.invoice.gst = baseInvoice.gst
      this.invoice.totalExGst = baseInvoice.totalExGst
    },
    async addInvoice() {
      this.setIsUpdateInvoice(true)
      this.isLoadingInvoice = true
      this.invoice = await MiscQuoteInvoiceService.getNewEntity()
      this.existingInvoice = this.innerValue.invoices.find(i => !i.isNew && !i.deleted)
      if (this.existingInvoice) {
        this.invoice.invoiceNo = this.existingInvoice.invoiceNo
      }
      this.updateInvoice()
      this.invoice.invoiceType = InvoiceTypes.Miscellaneous
      this.invoice.assetId = this.innerValue.customerId
      this.invoice.assetType = AssetTypes.Customer
      this.invoice.assetName = this.storeCustomer.fullname
      this.invoice.contactId = this.innerValue.contactId
      this.invoice.invoicePayingType = InvoicePayerTypes.Customer
      this.selectedInvoiceDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())

      // Add items to invoice
      // const items = this.innerValue.items.filter(i => !i.deleted)
      // const vm = this
      // items.forEach(i => {
      //   const newItem = new InvoiceItemModel(
      //     vm.$userInfo.companyId,
      //     vm.invoice.invoiceId,
      //     i.itemGlCode,
      //     i.itemDesc,
      //     i.quantity,
      //     i.unitPrice,
      //     vm.innerValue.gstRate
      //   )
      //   newItem.quoteItemId = i.miscQuoteItemId
      //   this.invoice.items.push(newItem)
      // })

      this.invoice.includedQuotes.splice(this.invoice.includedQuotes.length, 1, this.innerValue.miscQuoteId)
      this.innerValue.invoices.splice(this.innerValue.invoices.length, 1, this.invoice)

      this.isLoadingInvoice = false
    },
    deleteInvoice() {
      if (this.invoice.isNew) {
      const index = this.innerValue.invoices
          .map(function(obj) {
            return obj.invoiceId
          })
          .indexOf(this.invoice.invoiceId)
        this.innerValue.invoices.splice(index, 1)
        this.invoice = null
      } else {
        this.invoice.deleted = true
        this.invoice.isDeleted = true
      }
      this.setIsUpdateInvoice(false)
      this.getInvoice()
    },
    showChangeInvoiceNoModal() {}
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>