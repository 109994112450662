<template>
  <div>
    <div class="field">
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field-body">
            <div class="field has-addons">
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  v-model="filter.query"
                  type="text"
                  placeholder="Start typing to filter"
                  @input="search">
                <span class="icon is-left">
                  <i class="mdi mdi-magnify mdi-18px" />
                </span>
              </div>
              <div class="control">
                <a
                  class="button is-primary is-outlined"
                  @click="reset()">
                  <span class="icon">
                    <i class="mdi mdi-close mdi-18px" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
      :columns="columns"
      :page-index="filter.pageIndex"
      :page-size="filter.pageSize"
      :total-rows="totalRows"
      :is-loading="isTableLoading"
      :sort-column="filter.sortColumn"
      :sort-order="filter.sortOrder"
      @pageChanged="onPageChange"
      @sort="sort">
      <tr
        v-for="(item,index) in quickItems"
        :key="item.itemQuickId"
        @dblclick="addNewItem(item, index)"
        :class="{ 'is-selected' : selectedRow === index }"
        @click="highlightSelected(index, $event)">
        <td>{{ item.itemNo | pad(3) }}</td>
        <td>{{ item.description }}</td>
        <td class="has-text-right">{{ item.quantity }}</td>
        <td>
          <div class="has-text-right">{{ item.price.toFixed(2) }}</div>
        </td>
        <td>
          <div class="has-text-centered">
            <a
              class="button is-primary is-small is-inverted tooltip is-tooltip-topright"
              data-tooltip="Click or double-click row to add item"
              @click="addNewItem(item, index)">
              <span class="icon is-medium">
                <i class="mdi mdi-plus mdi-24px" />
              </span>
            </a>
          </div>
        </td>
      </tr>
      <template slot="empty">
        <div class="content has-text-grey has-text-centered">
          <span icon="icon is-large">
            <i class="mdi mdi-36px mdi-emoticon-sad" />
          </span>
          <p>Nothing</p>
        </div>
      </template>
    </bulma-table>
  </div>
</template>

<script>
import MiscQuoteService from './MiscQuoteService'
import _debounce from 'lodash.debounce'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import BulmaTable from '@/components/BulmaTable'
import { QuickItemColumns } from './columns'
import { MiscQuoteItemModel } from '@/classes/viewmodels'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'MiscQuoteAvailableItems',
  components: {
    BulmaTable
  },
  mixins: [NumberFiltersMixin],
  props: {
    value: {
      type: Array,
      default: function() {
        return []
      }
    },
    miscQuoteId: String,
    defaultGlAccount: String
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      quickItems: [],
      totalRows: 0,
      filter: {
        query: '',
        active: 1,
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 8
      },
      selectedRow: null,
      isTableLoading: false
    }
  },
  computed: {
    columns() {
      return QuickItemColumns
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.getData()
  },
  mounted() {},
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    async getData() {
      this.isTableLoading = true
      this.quickItems = await MiscQuoteService.getQuickItems(this.filter)
      this.totalRows = this.quickItems.length > 0 ? this.quickItems[0].totalRows : 0
      this.isTableLoading = false
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.getData()
    },
    reset() {
      this.filter.pageIndex = 1
      this.filter.query = ''
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.getData()
    },
    search: _debounce(function() {
      this.filter.pageIndex = 1
      this.getData()
    }, 500),
    addNewItem(item, index) {
      // Find item by item no
      const itemExist = this.innerValue.find(i => i.invoiceItemDesc === item.description && !i.isDeleted)
      if (!itemExist) {
        const newItem = new MiscQuoteItemModel(
          this.miscQuoteId,
          item.itemNo,
          item.description,
          item.quantity,
          item.price,
          '41110',
          this.innerValue.length + 1
        )
        this.innerValue.push(newItem)
      } else {
        this.$notification.openNotificationWithType('warning', 'Misc Quote', 'Item is already in selected list. Item not added.')
      }
    }
  }
}
</script>
