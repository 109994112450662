<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <slot name="text-title" />
      </p>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <label class="label">Type</label>
        <div class="control">
          <input class="is-checkradio"
            id="company"
            type="radio"
            name="company"
            :value="true"
            v-model="isMiscQuotePrint">
          <label for="company">Quote</label>
          <input class="is-checkradio"
            id="person"
            type="radio"
            name="person"
            :value="false"
            v-model="isMiscQuotePrint">
          <label for="person">Job Card</label>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="ok()"
        data-tooltip="Generate report(s)"
        :disabled="okDisabled">
        <span class="icon is-small">
          <i class="mdi mdi-18px mdi-printer" />
        </span>
        <span>Ok</span>
      </button>
      <button class="button tooltip"
        @click="cancel()"
        data-tooltip="Cancel"
        :disabled="cancelDisabled">Cancel</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'

export default {
  name: 'MiscQuoteInvoicePrintModal',
  components: {
    BaseModalEx
  },
  mixins: [],
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-printer'
    },
    okDisabled: {
      type: Boolean,
      default: false
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      isActive: false,
      isMiscQuotePrint: true
    }
  },
  computed: {},
  watch: {
    active(value) {
      this.isActive = value
    }
  }
  ,
  created() {},
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    ok() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('ok', this.isMiscQuotePrint)
    },
    cancel() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('cancel', this.isMiscQuotePrint)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
