<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-new="false"
      :show-print="entity && !entity.isNew"
      :show-delete="entity && !entity.isNew"
      :show-save="true"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <misc-quote-side-menu v-if="entity"
          :entity-name="`${entity.prefix}${entity.miscQuoteNo}${entity.suffix}`"
          v-model="entity"
          ref="sideMenu" />
      </div>
      <div class="column">
        <router-view v-if="entity"
          v-model="entity"
          ref="currentChild"
          :is-tiled="false" />
      </div>
    </div>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <confirm-modal v-if="entity"
      :active.sync="isConfirmModalActive"
      @ok="deleteEntity(true)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete Misc Quote</p>
      <p slot="text-content">
        Quote
        <span class="has-text-primary has-text-weight-bold">{{ `${entity.prefix}${entity.miscQuoteNo}${entity.suffix}` }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
    <misc-quote-print-modal v-if="isMiscQuotePrintModalActive"
      :active.sync="isMiscQuotePrintModalActive"
      @ok="print"
      @cancel="isMiscQuotePrintModalActive = false"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Misc Quote Print Options</p>
    </misc-quote-print-modal>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import MiscQuoteSideMenu from './MiscQuoteSideMenu'
import MiscQuoteValidation from './MiscQuoteValidation'
import MiscQuoteService from './MiscQuoteService'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import MiscQuoteRoutes from './route-types'
import { AppHeaderButtonTypes, EventHubTypes, RemarkTypes, AssetTypes } from '@/enums'
import _isEmpty from 'lodash/isEmpty'
import HttpStatus from '@/components/http-status'
import { UnsavedModal, ConfirmModal } from '@/components/BulmaModal'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import CustomerRoutes from '@/views/customer/route-types'
import ContactRoutes from '@/views/contact/route-types'
import StoreUtil from '@/store/utils'
import MiscQuotePrintModal from './components/MiscQuotePrintModal'

export default {
  name: 'MiscQuoteView',
  components: {
    AppDetailHeader,
    MiscQuoteSideMenu,
    UnsavedModal,
    ConfirmModal,
    MiscQuotePrintModal
  },
  mixins: [StoreMixin, MiscQuoteValidation],
  props: {},
  data: () => {
    return {
      entity: null,
      isSkipSave: false,
      isSaveContinue: false,
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isConfirmModalActive: false,
      isMiscQuotePrintModalActive: false
    }
  },
  computed: {
    validateError() {
      return this.$v.entity.$anyError && this.$v.entity.$anyDirty
    }
  },
  watch: {
    'entity.total': function(newVal, oldVal) {
      // Update invoice if in edit mode
      if (this.isUpdateInvoice) {
        const invoice = this.entity.invoices.find(i => !i.deleted)
        invoice.totalIncGst = this.entity.total
        invoice.gst = this.entity.gst
        invoice.totalExGst = this.entity.total - this.entity.gst
      }
    }
  },
  created() {
    this.getEntity()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          if (this.$route.name === MiscQuoteRoutes.MiscQuoteInvoice.name) {
            this.printInvoice()
          } else {
            this.isMiscQuotePrintModalActive = true
            // this.print()
          }
          break
        case AppHeaderButtonTypes.Delete:
          this.isConfirmModalActive = true
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      this.$showSpinner()
      if (reset) {
        this.clearSessionStorage()
        this.clearSnapshots(this.$route.params.entityId)
        this.clearInvoiceState()
      }
      if (!this.currentSnapshot) {
        await this.getStoreItem(this.$route.params.entityId)
      }
      this.entity = _cloneDeep(this.currentSnapshot)
      if (this.$guid.validGuid(this.entity.customerId)) {
        // Get and store customer in vuex store quotes/quoteVehicle
        this.getQuoteCustomer({ id: this.entity.customerId, refresh: reset })
      }
      this.$hideSpinner()
    },
    showItemsValidationErrors() {
      if (this.$v.detailGroup.$invalid) {
        this.$v.detailGroup.$touch()
      }
    },
    async save(isDelete) {
      const title = 'Misc Quote'
      if (this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        this.showItemsValidationErrors()
        return false
      }
      try {
        this.$showSpinner('Saving...')
        let response
        this.saveSnapshot(_cloneDeep(this.entity))
        if (this.entity.isNew) {
          response = await MiscQuoteService.postEntity(this.entity)
        } else if (this.snapshotDiff) {
          response = await MiscQuoteService.putEntity(_cloneDeep(this.entity), this.snapshotDiff)
        } else {
          this.$notification.openNotificationWithType('warning', title, 'No changes. Not saved')
        }
        this.$hideSpinner()
        if (this.isSaveContinue) {
          this.$notification.openNotificationWithType('success', title, isDelete ? `${title} deleted` : 'Save successful')
        } else if (response && response.status === HttpStatus.NO_CONTENT) {
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, isDelete ? `${title} deleted` : 'Save successful')
        } else if (response && response.status === HttpStatus.CREATED) {
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, `New ${title} added`)
        }
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        this.$hideSpinner()
        console.error(e)
        // if (e.response && e.response.request && e.response.request.status === HttpStatus.CONFLICT) {
        //   this.isSaveConflictModalActive = true
        // } else {
        //   this.$notification.openMessageXhrError(title, e)
        // }
        return false
      }
    },
    removeQueries() {
      // sessionStorage.removeItem(`${this.$userInfo.sessionId}|quote|returnUrl`)
      // sessionStorage.removeItem(`${this.$userInfo.sessionId}|quote|invoiceType`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      this.isPrintOptionsActive = false
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
        console.log(this.snapshotDiff)
        this.isUnsavedModalActive = true
      } else {
        if (this.returnUrl) {
          this.clearSessionStorage()
          this.clearSnapshots(this.entity.miscQuoteId)
          this.clearInvoiceState()
          this.setStoreCustomer(null)
          window.location.href = `${process.env.VUE_APP_ROOT_URI}${this.returnUrl}`
        } else if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else if (this.returnRoute && !_isEmpty(this.returnRoute)) {
          this.$router.push(this.returnRoute.fullPath)
        } else {
          this.$router.push({
            name: MiscQuoteRoutes.MiscQuoteListView.name
          })
        }
      }
    },
    print(isMiscQuotePrint) {
      if (isMiscQuotePrint) {
        this.printQuote()
      } else {
        this.printJobCard()
      }
    },
    async printQuote() {
      this.isMiscQuotePrintModalActive = false
      const params = Object.assign({
        CompanyID: this.$userInfo.companyId,
        MiscQuoteID: this.entity.miscQuoteId,
        CustomerID: this.entity.customerId,
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      })
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)
      const assetEmail = await MiscQuoteService.getAssetEmail(this.entity.customerId, AssetTypes.Customer)
      const emailer = new Emailer()
      if (assetEmail) {
        emailer.toEmails.push(assetEmail)
      }
      emailer.assetId = this.entity.miscQuoteId
      emailer.assetTypes = [AssetTypes.Customer]
      emailer.remark = `Quote ${this.entity.prefix}${this.entity.miscQuoteNo}${this.entity.suffix}`
      emailer.remarkTypeId = RemarkTypes.Remark
      emailer.subject = `Quote ${this.entity.prefix}${this.entity.miscQuoteNo}${this.entity.suffix}`
      emailer.reportName = `${this.entity.prefix}${this.entity.miscQuoteNo}${this.entity.suffix}`
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'rptMisquote' },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    async printJobCard() {
      const params = Object.assign({
        MiscQuoteID: this.entity.miscQuoteId,
        CompanyID: this.$userInfo.companyId,
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      })

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const assetEmail = await MiscQuoteService.getAssetEmail(this.entity.customerId, AssetTypes.Customer)
      const emailer = new Emailer()
      if (assetEmail) {
        emailer.toEmails.push(assetEmail)
      }
      emailer.assetId = this.entity.miscQuoteId
      emailer.assetTypes = [AssetTypes.Customer]
      emailer.remark = `Job Card ${this.entity.prefix}${this.entity.miscQuoteNo}${this.entity.suffix}`
      emailer.remarkTypeId = RemarkTypes.Remark
      emailer.subject = `Job Card ${this.entity.prefix}${this.entity.miscQuoteNo}${this.entity.suffix}`
      emailer.reportName = `${this.entity.prefix}${this.entity.miscQuoteNo}${this.entity.suffix}`
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)

      const routeData = this.$router.resolve({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'rptMisquoteJobCard' },
        query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
      })
      window.open(routeData.href, '_blank')
    },
    async printInvoice() {
      const invoice = this.entity.invoices.find(i => !i.deleted)
      if (!invoice) {
        return
      }
      const params = Object.assign({
        CompanyID: this.$userInfo.companyId,
        InvoiceID: invoice.invoiceId,
        CustomerID: this.entity.customerId
      })

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const assetEmail = await MiscQuoteService.getAssetEmail(this.entity.customerId, AssetTypes.Customer)
      const emailer = new Emailer()
      if (assetEmail) {
        emailer.toEmails.push(assetEmail)
      }
      emailer.assetId = this.entity.miscQuoteId
      emailer.assetTypes = [AssetTypes.Customer]
      emailer.remark = `Invoice ${invoice.prefix}${invoice.invoiceNo}${invoice.suffix}`
      emailer.remarkTypeId = RemarkTypes.Remark
      emailer.subject = `Invoice ${invoice.prefix}${invoice.invoiceNo}${invoice.suffix}`
      emailer.reportName = `${invoice.prefix}${invoice.invoiceNo}${invoice.suffix}`
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)

      const routeData = this.$router.resolve({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'rptMisquoteInvoice' },
        query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
      })
      window.open(routeData.href, '_blank')
    },
    deleteEntity(confirmDelete) {
      this.isConfirmModalActive = false
      if (confirmDelete) {
        this.entity.deleted = true
        this.save(true)
      }
    }
  },
  beforeRouteLeave: async function(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))
    const isEditCustomerContact = to.name === CustomerRoutes.CustomerDetail.name || to.name === ContactRoutes.ContactDetail.name
    if (isEditCustomerContact) {
      next()
    } else if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
      console.log(this.snapshotDiff)
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      this.clearSnapshots(this.entity.miscQuoteId)
      this.clearSessionStorage()
      this.clearInvoiceState()
      this.setStoreCustomer(null)
      if (to.name !== PrintPreviewRoutes.PrintPreview.name) {
        this.setReturnRoute({})
      }
      next()
    }
  }
}
</script>