import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Quote No.', 'miscQuoteNoRef', true, 'desc', 'left'),
  new TableColumn('Quote Date', 'miscQuoteDate', true, 'desc', 'left'),
  new TableColumn('Customer', 'customerName', true, 'desc', 'left'),
  new TableColumn('Total Inc. GST', 'totalExGst', true, 'desc', 'right'),
  new TableColumn('GST', 'gst', true, 'desc', 'right'),
  new TableColumn('Inv No', 'invoiceNo', true, 'desc', 'right'),
  new TableColumn('Active', 'active', false, 'desc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const QuickItemColumns = [
  new TableColumn('ItemNo', 'itemNo', true, 'asc', 'left'),
  new TableColumn('Desciption', 'description', true, 'asc', 'left'),
  new TableColumn('Quantity', 'quantity', false, 'asc', 'right'),
  new TableColumn('Unit Price', 'price', false, 'asc', 'right'),
  new TableColumn('', 'action', false, 'asc', 'centered')
]

export { Columns, QuickItemColumns }