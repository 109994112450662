import { mapGetters, mapActions } from 'vuex'

const moduleName = 'miscquotes'

export default {
  computed: {
    ...mapGetters(moduleName, {
      returnRoute: 'returnRoute',
      snapshots: 'snapshots',
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      snapshotDiff: 'snapshotDiff',
      isUpdateInvoice: 'isUpdateInvoice',
      storeCustomer: 'storeCustomer',
      readOnlyView: 'readOnlyView'
    }),
    ...mapGetters(['licenceExpired'])
  },
  methods: {
    ...mapActions(moduleName, [
      'getStoreItem',
      'addStoreItem',
      'saveSnapshot',
      'clearSnapshots',
      'setIsUpdateInvoice',
      'clearInvoiceState',
      'setStoreCustomer',
      'getQuoteCustomer',
      'setReturnRoute',
      'setReadOnlyView'
    ]),
    ...mapActions('printpreview', { setPrintPreviewReturnRoute: 'setReturnRoute', addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    ...mapActions('printpreview', { addReportParameters: 'addParameters', removeReportParameters: 'removeParameters' }),
    ...mapActions('customers', { setCustomerReturnRoute: 'setReturnRoute', editStoreCustomer: 'editStoreItem', addStoreCustomer: 'addStoreItem' }),
    ...mapActions('contacts', { setContactReturnRoute: 'setReturnRoute', addStoreContact: 'addStoreItem', editStoreContact: 'editStoreItem' })
  }
}
