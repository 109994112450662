<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <p class="title">
              Details
            </p>
          </div>
          <div v-if="activeInvoice"
            class="column is-narrow">
            <div class="tags">
              <span class="tag is-success is-pulled-right">Inv {{ `${activeInvoice.prefix}${activeInvoice.invoiceNo}${activeInvoice.suffix}` }} dated {{ $filters.formatDateTimezone(activeInvoice.invoiceDate, $userInfo.locale) }}</span>
            </div>
          </div>
        </div>
        <div class="field-body">
          <div class="field">
            <label class="label">Quote No.</label>
            <div class="control">
              <input class="input is-static"
                :value="`${innerValue.prefix}${innerValue.miscQuoteNo}${innerValue.suffix}`"
                type="text"
                placeholder="Quote No."
                readonly>
            </div>
          </div>
          <div class="field is-narrow">
            <label class="label">Quote Date</label>
            <input v-if="readOnlyView"
              class="input is-static"
              :value="$filters.formatDateTimezone(quoteDate, $userInfo.locale)"
              readonly>
            <v-date-picker v-else
              v-model="selectedQuoteDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              @input="selectedQuoteDate = $filters.fromISOWithCurrentTime($event.toISOString())"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Quote Date"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly>
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
        <div class="is-divider" />
        <div class="field">
          <label class="label">Customer</label>
          <div v-if="(gstPaid || readOnlyView) && selectedCustomer"
            class="field">
            <input class="input is-static"
              :value="selectedCustomer.customerName"
              readonly>
          </div>
          <div v-else-if="!gstPaid && !readOnlyView"
            class="field has-addons">
            <div class="control is-expanded">
              <multiselect v-if="activeCustomers"
                :class="{ 'is-danger' : $v.entity.customerId.$error }"
                v-model="selectedCustomer"
                deselect-label="Can't remove this value"
                track-by="assetId"
                label="assetName"
                placeholder="Select a customer"
                :options="activeCustomers"
                :loading="isCustomerLoading"
                :internal-search="false"
                :options-limit="customerFilter.pageSize"
                @select="selectCustomer"
                @remove="removeCustomer"
                @search-change="getCustomerDropdownFilter"
                :searchable="true"
                :show-labels="false"
                :allow-empty="true">
                <template slot="option"
                  slot-scope="props">
                  <span class="tag is-pulled-right"
                    :class="{'is-success' : props.option.assetType == 1, 'is-info' : props.option.assetType == 4}">
                    {{ props.option.assetType == 1 ? 'Customer' : 'Insurer' }}
                  </span>
                  <span>{{ `${props.option.assetName}` }}</span>
                </template>
                <span class="has-text-danger"
                  slot="noResult">Customer not found.</span>
              </multiselect>
              <span class="help is-danger"
                v-if="selectedCustomer && !selectedCustomer.active">Customer has been set to inactive.</span>
              <span class="help is-danger"
                v-if="$v.entity && !$v.entity.customerId.validGuid">Customer is required.</span>
            </div>
            <div class="control">
              <a class="button"
                :class="assetButtonClass"
                @click="onEditAddCustomer()"
                :disabled="gstPaid || readOnlyView">
                <span class="icon">
                  <i class="mdi mdi-24px"
                    :class="assetButtonIconClass" />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">Contact</label>
          <div v-if="gstPaid || readOnlyView"
            class="field">
            <input class="input is-static"
              :value="selectedContact ? selectedContact.fullname : ''"
              readonly>
          </div>
          <div v-else
            class="field has-addons">
            <div class="control is-expanded">
              <multiselect v-model="selectedContact"
                track-by="contactId"
                label="fullname"
                placeholder="Select a contact"
                :options="activeContacts"
                :searchable="true"
                :show-labels="false"
                :allow-empty="true"
                @select="selectContact"
                @remove="removeContact"
                @search-change="onContactSearchChange"
                @input.native="captureContactInput">
                <span class="has-text-danger"
                  slot="noResult">Contact not found.</span>
              </multiselect>
              <span class="help is-danger"
                v-if="selectedContact && !selectedContact.active">Contact has been set to inactive.</span>
            </div>
            <div class="control">
              <a class="button"
                :class="contactButtonClass"
                @click="onEditAddContact()"
                :disabled="!hasAssetSelected || gstPaid || readOnlyView">
                <span class="icon">
                  <i class="mdi mdi-24px"
                    :class="contactButtonIconClass" />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div class="is-divider" />
        <label class="label">Items</label>
        <table class="table is-bordered is-striped is-narrow is-fullwidth">
          <thead>
            <tr>
              <th>Description</th>
              <th class="has-text-right">
                Total
              </th>
            </tr>
          </thead>
          <tfoot class="has-multiple-totals">
            <tr>
              <th class="has-text-right">Total excl. GST</th>
              <th class="has-text-right has-text-success">{{ innerValue.total - innerValue.gst | formatCurrency($userInfo.locale) }}</th>
            </tr>
            <tr>
              <th class="has-text-right">GST</th>
              <th class="has-text-right has-text-success">{{ innerValue.gst | formatCurrency($userInfo.locale) }}</th>
            </tr>
            <tr>
              <th class="has-text-right">Total incl. GST</th>
              <th class="has-text-right has-text-success">{{ innerValue.total | formatCurrency($userInfo.locale) }}</th>
            </tr>
          </tfoot>
          <tbody v-if="activeItems.length > 0">
            <tr v-for="(item) in activeItems"
              :key="item.miscQuoteItemId">
              <td>{{ item.itemDesc }}</td>
              <td class="has-text-right">{{ item.quantity * item.unitPrice | formatNumber($userInfo.locale) }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="has-text-centered"
                colspan="3">
                <span icon="icon is-medium">
                  <i class="mdi mdi-36px mdi-dots-horizontal mdi-dark mdi-inactive" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="!innerValue.isNew && !readOnlyView">
          <div class="is-divider" />
          <div class="buttons has-addons is-centered">
            <span class="button"
              :class="{ 'is-success is-selected' : !innerValue.deleted }"
              @click="toggleActive(true)">
              <span class="icon is-small">
                <i class="mdi mdi-check" />
              </span>
              <span>Active</span>
            </span>
            <span class="button"
              :class="{ 'is-danger is-selected' : innerValue.deleted }"
              @click="toggleActive(false)">
              <span class="icon is-small">
                <i class="mdi mdi-close" />
              </span>
              <span>In-Active</span>
            </span>
          </div>
        </div>
      </article>
    </div>
    <div class="tile is-parent is-half">
      <article class="tile is-child box detail-page-tile">
        <p class="title">Remarks</p>
        <div class="field">
          <label class="label">Reference</label>
          <div class="control">
            <textarea v-if="!readOnlyView"
              class="textarea"
              v-model="innerValue.reference"
              placeholder=""
              maxlength="200"
              :disabled="readOnlyView" />
            <span v-else>{{ innerValue.reference }}</span>
          </div>
        </div>
        <div v-if="!readOnlyView"
          class="field">
          <div class="control is-pulled-right">
            <div class="is-pulled-right">
              <a class="button is-small is-success tooltip"
                data-tooltip="Add Remarks"
                @click="toggleRemarkModal()">
                <span class="icon">
                  <i class="mdi mdi-18px mdi-plus" />
                </span>
                <span>Remarks</span>
              </a>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">Remarks</label>
          <table class="table is-bordered is-striped is-narrow is-fullwidth">
            <thead />
            <tbody v-if="activeRemarks.length > 0">
              <tr v-for="(remark, index) in activeRemarks"
                :key="remark.remarkId">
                <td class="is-content-width">
                  <span class="tag is-info">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
                </td>
                <td v-if="readOnlyView">
                  <div class="control">
                    <span>{{ remark.remarks }}</span>
                  </div>
                </td>
                <td v-else>
                  <div class="control">
                    <textarea class="textarea"
                      v-model="remark.remarks"
                      rows="3"
                      placeholder="" />
                  </div>
                </td>
                <td v-if="!readOnlyView"
                  class="has-text-centered is-content-width has-vertical-middle">
                  <a class="button is-danger is-small is-inverted"
                    @click="deleteRemark(remark, index, remarkTypes.Remark)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-delete mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="has-text-centered"
                  colspan="2">
                  <span icon="icon is-medium">
                    <i class="mdi mdi-36px mdi-comment-text-outline mdi-dark mdi-inactive" />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="!readOnlyView"
          class="field">
          <div class="control is-pulled-right">
            <div class="is-pulled-right">
              <a class="button is-small is-success tooltip"
                data-tooltip="Add Remarks"
                @click="toggleRemarkModal()">
                <span class="icon">
                  <i class="mdi mdi-18px mdi-plus" />
                </span>
                <span>Remarks</span>
              </a>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">Printed Remarks</label>
          <table class="table is-bordered is-striped is-narrow is-fullwidth">
            <thead />
            <tbody v-if="activePrintedRemarks.length > 0">
              <tr v-for="(remark, index) in activePrintedRemarks"
                :key="remark.remarkId">
                <td class="is-content-width">
                  <span class="tag is-info">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
                </td>
                <td v-if="readOnlyView">
                  <div class="control">
                    <span>{{ remark.remarks }}</span>
                  </div>
                </td>
                <td v-else>
                  <div class="control">
                    <textarea class="textarea"
                      v-model="remark.remarks"
                      rows="3"
                      placeholder="" />
                  </div>
                </td>
                <td v-if="!readOnlyView"
                  class="has-text-centered is-content-width has-vertical-middle">
                  <a class="button is-danger is-small is-inverted"
                    @click="deleteRemark(remark, index, remarkTypes.PrintedRemark)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-delete mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="has-text-centered"
                  colspan="2">
                  <span icon="icon is-medium">
                    <i class="mdi mdi-36px mdi-comment-text-outline mdi-dark mdi-inactive" />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>
    </div>
    <remark-modal v-if="value && isRemarkModalActive"
      :show-remark="true"
      :show-printed-remark="true"
      :show-recurring-remark="true"
      :active.sync="isRemarkModalActive"
      @add="addRemarks"
      @cancel="toggleRemarkModal()">
      <p slot="text-title">Add Quick Invoice Remarks</p>
    </remark-modal>
  </div>
</template>

<script>
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import _cloneDeep from 'lodash/cloneDeep'
import Guid from '@/components/Guid'
import MiscQuoteService from './MiscQuoteService'
import Multiselect from 'vue-multiselect'
import _debounce from 'lodash.debounce'
import _isEmpty from 'lodash/isEmpty'
import { RemarkModal } from '@/components/BulmaModal'
import { RemarkTypes, EventHubTypes, AssetTypes } from '@/enums'
import { RemarkModel } from '@/classes/viewmodels'
import { ContactPayload } from '@/classes'
import StoreMixin from './storeMixin'

export default {
  name: 'MiscQuoteDetail',
  inject: ['$vv'],
  components: {
    Multiselect,
    RemarkModal
  },
  mixins: [StoreMixin, DateTimeFiltersMixin, NumberFiltersMixin],
  props: {
    value: null
  },
  data: () => {
    return {
      innerValue: null,
      gstPaid: false,
      quoteDate: null,
      selectedQuoteDate: null,
      customers: [],
      contacts: [],
      isCustomerLoading: false,
      selectedCustomer: null,
      selectedContact: null,
      contactButtonLoading: false,
      contactInput: '',
      customerFilter: {
        assetId: Guid.empty(),
        assetName: '',
        showCustomer: true,
        showInsurer: false,
        pageIndex: 1,
        pageSize: 50
      },
      assetButtonLoading: false,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      isRemarkModalActive: false
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    hasAssetSelected() {
      return this.selectedCustomer && !_isEmpty(this.selectedCustomer)
    },
    assetButtonIconClass() {
      return {
        'mdi-pencil': this.hasAssetSelected,
        'mdi-plus': !this.hasAssetSelected
      }
    },
    assetButtonClass() {
      return {
        'is-primary': this.hasAssetSelected,
        'is-success': !this.hasAssetSelected,
        'is-loading': this.assetButtonLoading
      }
    },
    hasContactSelected() {
      return this.selectedContact && !_isEmpty(this.selectedContact)
    },
    contactButtonIconClass() {
      return {
        'mdi-pencil': this.hasContactSelected,
        'mdi-plus': !this.hasContactSelected
      }
    },
    contactButtonClass() {
      return {
        'is-primary': this.hasContactSelected,
        'is-success': !this.hasContactSelected,
        'is-loading': this.contactButtonLoading
      }
    },
    activeRemarks() {
      return this.innerValue.remarks.filter((r) => !r.isDeleted)
    },
    activePrintedRemarks() {
      return this.innerValue.printedRemarks.filter((r) => !r.isDeleted)
    },
    remarkTypes() {
      return RemarkTypes
    },
    activeItems() {
      return this.innerValue.items.filter((i) => !i.isDeleted)
    },
    activeInvoice() {
      return this.innerValue.invoices.filter((i) => !i.isDeleted)[0]
    },
    activeContacts() {
      return this.contacts.filter((c) => c.active)
    },
    activeCustomers() {
      return this.customers.filter((c) => c.active)
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    selectedQuoteDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.miscQuoteDate = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.miscQuoteDate = null
      }
    },
    selectedCustomer(newVal, oldVal) {
      if (newVal && this.$guid.validGuid(newVal.assetId)) {
        this.getQuoteCustomer({ id: newVal.assetId, refresh: true })
      } else {
        this.setStoreCustomer(null)
      }
    }
  },
  created() {
    this.innerValue = _cloneDeep(this.value)
    this.quoteDate = new Date(`${this.innerValue.miscQuoteDate}`)
    this.selectedQuoteDate = new Date(`${this.innerValue.miscQuoteDate}`)
    this.customerFilter.assetId = this.innerValue.customerId
    this.getAssetDropdown()
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  mounted() {
    this.$v.detailGroup.$touch()
  },
  methods: {
    async selectCustomer(selected) {
      this.innerValue.customerId = selected.assetId
      this.innerValue.invoiceGlCode = selected.assetGlCode
      this.customerFilter.assetId = selected.assetId
      this.innerValue.contactId = Guid.empty()
      this.contacts = await MiscQuoteService.getContactDropdown(selected.assetId)
      this.selectedContact = null
    },
    removeCustomer(removed) {
      this.customerFilter.assetId = Guid.empty()
      this.innerValue.customerId = Guid.empty()
      this.innerValue.contactId = Guid.empty()
      this.selectedContact = null
      this.contacts = []
      this.getAssetDropdown()
    },
    async getAssetDropdown() {
      this.isCustomerLoading = true
      ;[this.customers, this.contacts] = await Promise.all([
        MiscQuoteService.getAssetDropdownProto(this.customerFilter),
        MiscQuoteService.getContactDropdown(this.innerValue.customerId)
      ])
      if (this.customerFilter.assetId !== Guid.empty()) {
        this.selectedCustomer = this.customers.find((c) => c.assetId === this.innerValue.customerId)
      }
      this.selectedContact = null
      if (this.innerValue.contactId !== Guid.empty()) {
        this.selectedContact = this.contacts.find((c) => c.contactId === this.innerValue.contactId)
      }
      this.isCustomerLoading = false
    },
    getCustomerDropdownFilter: _debounce(async function (query) {
      this.isCustomerLoading = true
      this.customerFilter.assetName = query
      this.customers = await MiscQuoteService.getAssetDropdownProto(this.customerFilter)
      this.isCustomerLoading = false
    }, 300),
    onEditAddCustomer() {
      this.assetButtonLoading = true
      if (this.hasAssetSelected) {
        this.setCustomerReturnRoute(this.$route)
        this.editStoreCustomer(this.selectedCustomer.assetId)
      } else {
        this.setCustomerReturnRoute(this.$route)
        this.addStoreCustomer(this.customerFilter.assetName)
      }
    },
    selectContact(selected) {
      this.innerValue.contactId = selected.contactId
    },
    removeContact(removed) {
      this.innerValue.contactId = Guid.empty()
    },
    onContactSearchChange(query) {
      this.isContactLoading = true
      if (query && this.selectedContact) {
        this.selectedContact = null
      }
      this.isContactLoading = false
    },
    captureContactInput(event) {
      this.contactInput = event.target.value
    },
    onEditAddContact() {
      if (!this.hasAssetSelected) {
        return false
      }
      this.contactButtonLoading = true
      this.setContactReturnRoute(this.$route)
      if (this.hasContactSelected) {
        this.editStoreContact(this.selectedContact.contactId)
      } else {
        const payload = new ContactPayload(this.contactInput, this.innerValue.customerId, AssetTypes.Customer, this.selectedCustomer.assetName)
        this.addStoreContact(payload)
      }
    },
    addRemarks(remark, printedRemark) {
      if (remark) {
        const newRemark = new RemarkModel(this.innerValue.invoiceId, RemarkTypes.Remark, remark)
        this.innerValue.remarks.push(newRemark)
      }
      if (printedRemark) {
        const newPrintedRemark = new RemarkModel(this.innerValue.invoiceId, RemarkTypes.PrintedRemark, printedRemark)
        this.innerValue.printedRemarks.push(newPrintedRemark)
      }
      this.toggleRemarkModal()
    },
    deleteRemark(remark, index, remarkType) {
      if (remark.isNew) {
        if (remarkType === this.remarkTypes.Remark) {
          this.innerValue.remarks.splice(index, 1)
        } else if (remarkType === this.remarkTypes.PrintedRemark) {
          this.innerValue.printedRemarks.splice(index, 1)
        }
      } else {
        remark.isDeleted = true
      }
    },
    toggleRemarkModal() {
      this.isRemarkModalActive = !this.isRemarkModalActive
    },
    toggleActive(active) {
      this.innerValue.deleted = !this.innerValue.deleted
    }
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>